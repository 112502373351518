/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ValidateSubdomainDto {
  slug: string;
}

export interface ResultWithCount {
  countAll: number;
  result: string[];
}

export interface ProhibitedSubdomain {
  id: number;
  name: string;
  reason: string;
  recordType: "system" | "registered" | "manager";
}

export type Object = object;

export interface CreateProhibitedDomainDto {
  name: string;
  reason: string;
}

export interface ValidateDomainDto {
  domain: string;
}

export type CreateDomainDto = object;

export type UpdateDomainDto = object;

export interface LoginDto {
  /** @format email */
  email: string;
  /**
   * @minLength 8
   * @maxLength 30
   */
  password: string;
}

export enum Role {
  GUEST = "GUEST",
  ADMIN = "ADMIN",
  MODER = "MODER",
  USER = "USER",
}

export interface User {
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  /** @default "USER" */
  role: Role;
  description: string;
  password: string;
  token: string;
}

export interface CreateUserDto {
  phone?: string;
  /** @format email */
  email?: string;
  first_name?: string;
  last_name?: string;
  password?: string;
  role?: Role;
  description?: string;
}

export interface UpdateUserDto {
  phone?: string;
  /** @format email */
  email?: string;
  first_name?: string;
  last_name?: string;
  password?: string;
  role?: Role;
  description?: string;
}

export interface Site {
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  userId: number;
  user: User;
  /** @maxLength 63 */
  slug: string;
  title: string;
  config: object;
  domains: string[];
  status: "active" | "inactive" | "pending";
  siteType: "doctor" | "clinic";
  medflexId: number;
  appToken: string;
  customDomain: string;
}

export enum SiteStatus {
  Active = "active",
  Inactive = "inactive",
  Pending = "pending",
}

export interface SiteStatusDto {
  id: number;
  status: "active" | "inactive" | "pending";
}

export interface VerificationEmailDto {
  /** @format email */
  email: string;
  sessionId: string;
}

export interface VerificationOtpDto {
  /** @pattern /^\d{6}$/ */
  code: string;
  sessionId: string;
}

export interface CreateSiteDto {
  sessionId: string;
  slug: string;
  domain?: string;
  branchesGroup: number;
  /** @minItems 1 */
  branches: number[];
  clinicProfile: "dental" | "cosmetic" | "multi" | "mono";
  clinicName: string;
  clinicDescription: string;
  workingHours: string;
  licenseNumber: string;
  inn: string;
  mainPhone: string;
  /** @minItems 1 */
  sections: string[];
  clinicLogo?: string;
  favicon?: string;
  /** @pattern ^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$ */
  mainColor?: string;
  /** @pattern ^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$ */
  secondaryColor?: string;
}

export interface SitePasswordDto {
  siteId: number;
  /**
   * @minLength 8
   * @maxLength 64
   */
  password: string;
}

export interface GetLpuGroupResponseDto {
  id: number;
  name: string;
}

export interface GetLpuResponseDto {
  id: number;
  partner_lpu_id: string;
  lpu_group_id: number;
  name: string;
  address: string;
  lon: number;
  lat: number;
  town_id: number;
  town_name: string;
  district_id: number;
  legal_entity: string;
  second_name_is_required: boolean;
  specialities: number[];
  phone: string | null;
  direct_appointment_is_supported: boolean;
  cancel_appointment_is_supported: boolean;
  is_visible: boolean;
  has_prices: boolean;
  total_rating?: number;
  lpu_rating?: number;
  doctors_rating?: number;
  inn?: string;
  site: string | null;
  prodoctorov_lpu_url?: string;
}

export interface SessionInfoDto {
  confirmed: boolean;
  lpuGroups: GetLpuGroupResponseDto[];
  lpus: GetLpuResponseDto[];
  dns: string[];
}

export interface CreateSessionDto {
  /**
   * @minLength 32
   * @maxLength 128
   */
  token: string;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Clinilink
 * @version 1.0.0
 * @contact
 *
 * Clinilink  API description
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  debug = {
    /**
     * No description
     *
     * @tags App
     * @name Debug
     * @request GET:/debug
     */
    debug: (params: RequestParams = {}) =>
      this.http.request<string, any>({
        path: `/debug`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  options = {
    /**
     * No description
     *
     * @tags App
     * @name GetModuleOptions
     * @request GET:/options/{moduleName}
     */
    getModuleOptions: (moduleName: string, params: RequestParams = {}) =>
      this.http.request<Object, any>({
        path: `/options/${moduleName}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags App
     * @name UpdateModuleOptions
     * @request POST:/options/{moduleName}
     */
    updateModuleOptions: (moduleName: string, data: any, params: RequestParams = {}) =>
      this.http.request<boolean, any>({
        path: `/options/${moduleName}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  domain = {
    /**
     * No description
     *
     * @tags Domains
     * @name ValidateSubdomain
     * @request POST:/domain/validate/subdomain
     */
    validateSubdomain: (data: ValidateSubdomainDto, params: RequestParams = {}) =>
      this.http.request<boolean, boolean>({
        path: `/domain/validate/subdomain`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Domains
     * @name GetProhibitedDomains
     * @request GET:/domain/prohibited
     */
    getProhibitedDomains: (
      query?: {
        /**
         * @min 1
         * @default 1
         */
        current?: number;
        /**
         * @min 3
         * @max 100
         * @default 20
         */
        pageSize?: number;
        sortColumns?: Object;
        id?: number;
        name?: string;
        reason?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<
        ResultWithCount & {
          result?: ProhibitedSubdomain[];
        },
        any
      >({
        path: `/domain/prohibited`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Domains
     * @name CreateProhibitedDomain
     * @request POST:/domain/prohibited
     */
    createProhibitedDomain: (data: CreateProhibitedDomainDto, params: RequestParams = {}) =>
      this.http.request<Object, any>({
        path: `/domain/prohibited`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Domains
     * @name DeleteProhibitedDomain
     * @request DELETE:/domain/prohibited/{id}
     */
    deleteProhibitedDomain: (id: number, params: RequestParams = {}) =>
      this.http.request<boolean, any>({
        path: `/domain/prohibited/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Domains
     * @name ValidateDomain
     * @request POST:/domain/validate-domain
     */
    validateDomain: (data: ValidateDomainDto, params: RequestParams = {}) =>
      this.http.request<boolean, any>({
        path: `/domain/validate-domain`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Domains
     * @name Create
     * @request POST:/domain
     */
    create: (data: CreateDomainDto, params: RequestParams = {}) =>
      this.http.request<string, any>({
        path: `/domain`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Domains
     * @name FindAll
     * @request GET:/domain
     */
    findAll: (params: RequestParams = {}) =>
      this.http.request<string, any>({
        path: `/domain`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Domains
     * @name FindOne
     * @request GET:/domain/{id}
     */
    findOne: (id: string, params: RequestParams = {}) =>
      this.http.request<string, any>({
        path: `/domain/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Domains
     * @name Update
     * @request PATCH:/domain/{id}
     */
    update: (id: string, data: UpdateDomainDto, params: RequestParams = {}) =>
      this.http.request<string, any>({
        path: `/domain/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Domains
     * @name Remove
     * @request DELETE:/domain/{id}
     */
    remove: (id: string, params: RequestParams = {}) =>
      this.http.request<string, any>({
        path: `/domain/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name Login
     * @summary Авторизация пользователя
     * @request POST:/auth/login
     * @secure
     */
    login: (data: LoginDto, params: RequestParams = {}) =>
      this.http.request<User, User>({
        path: `/auth/login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags Users
     * @name Create
     * @summary Создать пользователя
     * @request POST:/users
     */
    create: (data: CreateUserDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/users`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Доступные фильтры зависят от роли
     *
     * @tags Users
     * @name FindAll
     * @summary Получение списка пользователей
     * @request GET:/users
     */
    findAll: (
      query?: {
        /**
         * @min 1
         * @default 1
         */
        current?: number;
        /**
         * @min 3
         * @max 100
         * @default 20
         */
        pageSize?: number;
        sortColumns?: Object;
        id?: number;
        /** @format email */
        email?: string;
        first_name?: string;
        last_name?: string;
        role?: string[];
        /** @format date-time */
        created_at?: string;
        is_active?: boolean;
        /** @pattern /^[\+\d\s\(\)-]*$/ */
        phone?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<
        ResultWithCount & {
          result?: User[];
        },
        any
      >({
        path: `/users`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name Update
     * @summary Обновление пользователя
     * @request PATCH:/users/{id}
     */
    update: (id: number, data: UpdateUserDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/users/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Доступные фильтры зависят от роли
     *
     * @tags Users
     * @name Info
     * @summary Получение информации авторизованного пользователя
     * @request GET:/users/info
     */
    info: (params: RequestParams = {}) =>
      this.http.request<User, User>({
        path: `/users/info`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name Status
     * @summary Блокировка/разблокировка пользователя
     * @request PATCH:/users/status/{id}/{is_active}
     */
    status: (id: number, isActive: number, params: RequestParams = {}) =>
      this.http.request<Object, boolean>({
        path: `/users/status/${id}/${isActive}`,
        method: "PATCH",
        format: "json",
        ...params,
      }),
  };
  site = {
    /**
     * No description
     *
     * @tags Site
     * @name FindAll
     * @request GET:/site
     */
    findAll: (
      query?: {
        /**
         * @min 1
         * @default 1
         */
        current?: number;
        /**
         * @min 3
         * @max 100
         * @default 20
         */
        pageSize?: number;
        sortColumns?: Object;
        status?: SiteStatus[];
        userId?: number;
        id?: number;
        title?: string;
        slug?: string;
        /** @format date-time */
        createdAt?: string;
        domains?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<
        ResultWithCount & {
          result?: Site[];
        },
        any
      >({
        path: `/site`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name Create
     * @request POST:/site
     */
    create: (data: CreateSiteDto, params: RequestParams = {}) =>
      this.http.request<Site, any>({
        path: `/site`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name SetStatus
     * @request PATCH:/site/status
     */
    setStatus: (data: SiteStatusDto, params: RequestParams = {}) =>
      this.http.request<boolean, any>({
        path: `/site/status`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name SendCode
     * @request POST:/site/verification-email
     */
    sendCode: (data: VerificationEmailDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/site/verification-email`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name VerificationCode
     * @request POST:/site/verification-otp
     */
    verificationCode: (data: VerificationOtpDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/site/verification-otp`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name ResetAdminPassword
     * @request PATCH:/site/reset-admin-password
     */
    resetAdminPassword: (data: SitePasswordDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/site/reset-admin-password`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  medflex = {
    /**
     * No description
     *
     * @tags medflex
     * @name GetSession
     * @request GET:/medflex/session/{sessionId}
     */
    getSession: (sessionId: string, params: RequestParams = {}) =>
      this.http.request<SessionInfoDto, any>({
        path: `/medflex/session/${sessionId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags medflex
     * @name CreateSession
     * @request POST:/medflex/session
     */
    createSession: (data: CreateSessionDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/medflex/session`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
}
