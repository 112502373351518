import ProForm, { ProFormText } from '@ant-design/pro-form';
import { ModalForm } from '@ant-design/pro-components';

interface ProhibitedDomainFormProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (data: any) => Promise<boolean>;
}

export const ProhibitedDomainForm = (props: ProhibitedDomainFormProps) => {

  const validateDomain = (rule: any, value: string) => {
    // Обновленное регулярное выражение
    const domainRegex = /^([a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?\.)*[a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?$|^[a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?$/;
    if (!value || domainRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('Пожалуйста, введите правильный домен или субдомен');
  };

  return <ModalForm
    title={'Добавить новую запись в реестр запрещенных доменов'}
    onFinish={props.onSubmit}
    open={props.visible}

    modalProps={{
      onCancel: () => {props.onClose();},
      destroyOnClose: true,
    }}
  >
    <ProFormText
      name={'name'}
      label={'Домен'}
      required={true}
      help={'Доменное имя или субдомен. Для внутренних субдоменов указывать без основного домена'}
      rules={[
        { required: true, message: 'Это поле обязательно для заполнения' },
        { validator: validateDomain },  // Валидация домена
      ]}
    />

    <ProFormText
      name={'reason'}
      label={'Причина'}
      required={true}
      rules={[
        { required: true, message: 'Это поле обязательно для заполнения' },
      ]}
    />
  </ModalForm>;
};
