import React, { useState } from 'react';
import { Select, Spin } from 'antd';
import { User } from '../../services/Api';
import { Client } from '../../services/Client';


type SelectUserProps = {
  value?: User,
  onChange?:(value:number)=>void
}

export const SelectUser = (props: SelectUserProps) => {

  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [selected,setSelected]=useState<User|undefined>(undefined)

  const getUsers = async (key: string) => {
    if (!key || key.length < 3) return;
    setLoading(true);

    const result = await Client.users.findAll({
      current: 1,
      pageSize: 20,
      email: key
    });
    setUsers(result.data.result);
    setLoading(false);
  };

  const onChange = (value: number) => {
    const _user = users.find(item => item.id == value);
    setSelected(_user)
    props.onChange?.(value);
  };

  return <>
    <label>{`${selected?.first_name || ''} ${selected?.last_name || ''}`}</label>
    <Select
      placeholder={'начните вводить email'}
      onChange={e => onChange(e)}
      options={users}
      showSearch
      notFoundContent={loading ? <Spin size="small"/> : null}
      filterOption={false}
      fieldNames={{
         label: 'email',
        value: 'id',
      }}
      onSearch={async (key) => {
        await getUsers(key);
      }}
    />
  </>;
};
