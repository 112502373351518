import { MutableRefObject, useRef, useState } from 'react';
import { ColumnsState, ProColumns } from '@ant-design/pro-components';
import { Role, Site, SiteStatus, User } from '../services/Api';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone, ControlTwoTone,
  EditOutlined,
  KeyOutlined, LockTwoTone,
  PlusOutlined, UnlockTwoTone,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
} from '@ant-design/icons';
import ButtonGroup from 'antd/es/button/button-group';
import { Button } from '../components/button/button';
import { Client } from '../services/Client';
import { message, Popconfirm } from 'antd';
import { TableFullHeight } from '../components/table/table-full-height';
import ChangePasswordModalForm from '../components/form/change-password-modal.form';
import { UserButton } from '../components/button/user-button';

export const SitesPage = (props: any) => {
  const tableRef: MutableRefObject<any> = useRef();
  const [editPassword, setEditPassword] = useState<Site>();
  const [columnsStateMap, setColumnsStateMap] = useState<Record<string, ColumnsState>>({});
  const [createSiteFormVisible, setCreateSiteFormVisible] = useState(false);

  const columns: ProColumns[] = [
    {
      title: 'id',
      //width: 80,
      dataIndex: 'id',
      copyable: true,
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      //width: 50,
      valueType: 'dateTime',
      sorter: true,

    },
    {
      title: 'Описание',
      //width: 150,
      dataIndex: 'title',
    },
    {
      title: 'Субдомен',
      dataIndex: 'slug',
      width:150,
      copyable: true,
      render:(text,record)=>(record.slug)
    },
    {
      title: 'Домены',
      ///width: 150,
      dataIndex: 'domains',
      render: (text, record) => (record.domains.join(', ')),
    },
    {
      title:'Прикрепленный домен',
      width: 150,
      dataIndex:'customDomain'
    },
    {
      title: 'Пользователь',
      //width: 100,
      dataIndex: 'user',
      //@ts-ignore
      valueType:'user',
      render: (_, record) => {
        return <UserButton user={record.user}/>
       // return <>{record.user.first_name} {record.user.last_name}</>;
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      //width: 50,
      valueType: 'checkbox',
      valueEnum: {
        active: { text: 'Активен', status: 'Success' },
        inactive: { text: 'Неактивен', status: 'Default' },
        pending: { text: 'В ожидании', status: 'Processing' },
      },
    },

    {
      key: 'option',
      width:80,
      fixed:'right',
      valueType: 'option',
      filters: false,
      filtered: false,
      //width: 120,
      render: (_, record: Site, index, action) => {
        let blockButton = null;
        switch (record.status) {
          case SiteStatus.Active:
            blockButton = <Popconfirm
              placement={'left'}
              title={'Заблокировать'}
              onConfirm={async () => {
                await Client.site.setStatus({
                  id: record.id,
                  status: SiteStatus.Inactive
                })
                tableRef.current.reload();
              }}
            >
              <Button
                hint="Заблокировать"
                type="primary"
                size="small"

                icon={<UnlockTwoTone twoToneColor={'#fff'} />}/>
            </Popconfirm>;
            break;
          case 'inactive':
            blockButton = <Popconfirm
              placement={'left'}
              title={'Разблокировать?'}
              onConfirm={async () => {
                //await Client.users.status(record.id, 1);
                await Client.site.setStatus({
                  id:record.id,
                  status:SiteStatus.Active
                })
                tableRef.current.reload();
              }}
            ><Button hint="Разблокировать" danger={true} type="primary" size="small" icon={<LockTwoTone twoToneColor={'#fff'}/>}/></Popconfirm>;
            break;

          default:
            blockButton = <Button loading={true} size={'small'}/>;
            break;

        }
        return (<ButtonGroup style={{flexGrow: 1}}>
          <Button
            hint="Сбросить пароль Администратора сайта"
            style={{backgroundColor:'orange',color:'white'}}
            size="small"
            icon={<KeyOutlined />}
            onClick={() => {
             setEditPassword(record)
            }}
          />
          <Button hint="Посмотреть config.php"
                  type="default"
                  size="small"
                  icon={<ControlTwoTone />}
                  onClick={() => { alert('disabled') }}/>
          {}

          {blockButton}
        </ButtonGroup>);
      },
    },
  ];

  const toolbarRender = () => {
    return [
      <Button
        onClick={async e => {
          const token = prompt('Введите токен medflex');
          if (token) {
            try{
              const result=await Client.medflex.createSession({ token })
              // @ts-ignore
              window.open(result.data['url'], '_blank');

            }catch(err) {
              message.error('Ошибка создания сессии')
            }

          }
        }}
        key="button"
        hint={'Новый сайт'}
        icon={<PlusOutlined/>}
        type="primary"
      />,
    ];
  };

  return <>
    <TableFullHeight

      remapFilters={{'user':'userId'}}
      options={{ fullScreen: true }}
      actionRef={tableRef}
      headerTitle={'Сайты'}
      columns={columns}
      rowKey={'id'}
      search={{ filterType: 'light' }}
      request={Client.site.findAll}
      toolBarRender={toolbarRender}
      onRow={(record: Site, rowIndex: number) => {
        return {
          onDoubleClick: () => {

          },
        };
      }}
      columnsState={{
        value: columnsStateMap,
        onChange: setColumnsStateMap,
      }}

      onChange={(pagination:any, filters:any, sorter:any)=>{

      }}
    />

    <ChangePasswordModalForm
      siteId={editPassword?.id||0}
      visible={editPassword!=undefined}
      onClose={()=>{setEditPassword(undefined)}}/>
  </>;
};
