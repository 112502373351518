
export const RuRu = {
  appName: 'CMSDeployer',

  tableForm:{
    inputPlaceholder:''
  },

  routes:{
    dashboard:'Дашборд',
    users:'Пользователи',
    sites:'Сайты',
    settings:'Настройки',
    domain_records:'Записи доменов'
  },


  errors:{
    'user_not_found':'Пользователь не найден',
    'phone must be a valid phone number':'Должен быть действующим номером телефона',
    isNotEmpty: 'Не должно быть пустым',
    isPhoneNumber: 'Укажите номер телефона',
    isAlphaCyr: 'Только символы A-z,А-я',
    isAlpha: 'Только символы A-z',
    isText: 'Только символы A-z,А-я,0-9 и пунктуации',
    isEmail: 'Укажите EMail',
    isAlphanumericCyr: 'Только символы A-z,А-я,0-9',
    isAlphanumeric: 'Только символы A-z,0-9',
    IsUniqueDBConstraint: 'Уже существует',
    isString: 'Должно быть строкой',
    isEnum: 'Выберите из списка',
    min: 'Минимальное значение',
    isSysName: 'Только символы A-z 0-9 _-',
    arrayNotEmpty: 'Не должно быть пустым',
    form: 'Ошибки в заполнении формы',
    'A record not found for the domain. Please check the DNS settings':'A - запись не найдена. Проверьте DNS настройки вашего домена.',
    'Domain already exists':'Этот домен нельзя добавить. Пожалуйста, свяжитесь с поддержкой.'
  }


};


