import { ProColumns } from '@ant-design/pro-components';
import { Client } from '../services/Client';
import { TableFullHeight } from '../components/table/table-full-height';
import { MutableRefObject, useRef, useState } from 'react';
import { Button } from '../components/button/button';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ProhibitedDomainForm } from '../components/form/prohibited-domain.form';
import { message, Popconfirm } from 'antd';
import { ProhibitedSubdomain } from '../services/Api';

export const DomainRecordsPage = () => {
  const tableRef: MutableRefObject<any> = useRef();
  const [createDomainFormVisible, setCreateDomainFormVisible] = useState<boolean>(false);

  const columns: ProColumns[] = [
    {
      title: 'id',
      width: 80,
      dataIndex: 'id',
      copyable: true,
      sorter: true,
      defaultSortOrder: 'descend',
      fixed:'left'
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      width: 300,
      sorter: true,
      copyable: true,
      render:(text, record) => (record.name ? record.name : '-')
    },
    {
      title: 'Описание',
      //width: 300,
      dataIndex: 'reason',
      copyable: true,
      render:(text, record) => (record.reason ? record.reason : '-')
    },
    {
      title: 'Тип',
      dataIndex: 'recordType',
      render:(text, record) => (record.recordType ? record.recordType : '-')
    },
    {
      valueType: 'option',
      key: 'option',
      render: (text, record: ProhibitedSubdomain) => {
        if (record.recordType != 'manager') return [];
        return [
          <Popconfirm
            title={'Удалить?'}
            onConfirm={async () => {
              try {
                await Client.domain.deleteProhibitedDomain(record.id);
                tableRef.current.reload();
              }catch(err) {
                console.log(err)
                message.error('Ошибка удаления домена');
              }

            }}
          >
            <a><DeleteOutlined/></a>
          </Popconfirm>,
        ];
      },
    },
  ];

  const toolbarRender = () => {
    return [
      <Button
        onClick={e => setCreateDomainFormVisible(true)}
        key="button"
        hint={'Новая запись'}
        icon={<PlusOutlined/>}
        type="primary"
      />,
    ];
  };

  return <>
    <TableFullHeight
      options={{ fullScreen: true }}
      actionRef={tableRef}
      headerTitle={'Записи в реестре'}
      columns={columns}
      rowKey={'id'}
      search={{ filterType: 'light' }}
      request={Client.domain.getProhibitedDomains}
      toolBarRender={toolbarRender}

    />

    <ProhibitedDomainForm
      onSubmit={async (data) => {
        try {
          await Client.domain.createProhibitedDomain(data);
          setCreateDomainFormVisible(false);
          tableRef.current.reload();
        } catch (e) {
          message.error('Ошибка добавления домена. Возможно уже существует');
          console.log(e);
        }

        return true;
      }}
      visible={createDomainFormVisible}
      onClose={() => setCreateDomainFormVisible(false)}
    />
  </>;
};
