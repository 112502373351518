import ProForm from '@ant-design/pro-form';
import Field from '@ant-design/pro-form/es/components/Field';

import Tabs from 'antd/lib/tabs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Client } from '../services/Client';
import { arrayToEnum } from '../common/helper';


export const SettingsPage = () => {
  const [settings, setSettings] = useState<{ form: object, values: any[] }>({ form: {}, values: [] });
  const {t}=useTranslation()
  const titles = {
    default: 'Основные',
  };

  useEffect(() => {
    Client.options.getModuleOptions('app').then(result => {
      // @ts-ignore
      setSettings(result.data);
    });
  }, []);

  return <Tabs defaultActiveKey={'default'}>
    {Object.keys(settings?.form).map((fieldset:string) => {
      const values = settings.values.find((item) => item.key == fieldset)?.values;

      // @ts-ignore
      return <Tabs.TabPane key={fieldset} tab={titles[fieldset]}>
        <ProForm onFinish={async (data) => {
          await Client.options.updateModuleOptions('app', data);
        }}>
          {/*@ts-ignore*/}
          {settings.form[fieldset].map((field:any) => {

            return <Field
              fieldProps={field?.props}
              name={[fieldset, field.name]}
              key={field.name}
              label={field?.label||field.name}
              mode={'edit'}
              valueType={field.type}
              //valueEnum={arrayToEnum(field.default)}
              initialValue={values?.[field.name]}
            />;
          })}
        </ProForm>
      </Tabs.TabPane>;
    })}
  </Tabs>;
};
