import React, { useState } from 'react';

import { Avatar, Col, Drawer, Row, Typography } from 'antd';

import Paragraph from 'antd/lib/typography/Paragraph';
import { User } from '../../services/Api';
import { Button } from './button';

type UserButtonProps = {
  user: User
}

export const UserButton = (props: UserButtonProps) => {
  const [showUser, setShowUser] = useState(false);
  if (!props.user) return null;

  return <>
    <Button onClick={e => setShowUser(true)} size={'small'} hint={'профиль'}>{`${props.user.first_name} ${props.user.last_name}`}</Button>
    <Drawer
      destroyOnClose={true}
      visible={showUser}
      onClose={e => setShowUser(false)}
      maskClosable={true}
      width={480}
      title={`${props.user.first_name} ${props.user.last_name}`}
    >
      <Row>
        <Col span={20}>
          <div style={{ marginLeft: 16 }}>
            <p><strong>Имя</strong>: <Typography.Text copyable={true}>{props.user.first_name} {props.user.last_name}</Typography.Text></p>
            <p><strong>ID</strong>: <Typography.Text copyable={true}>{props.user.id}</Typography.Text></p>
            <p><strong>Телефон</strong>: <Typography.Text copyable={true}>{props.user.phone}</Typography.Text></p>
            <p><strong>Email</strong>: <Typography.Text copyable={true}>{props.user.email}</Typography.Text></p>
            <p><strong>Роль: </strong> {props.user.role}</p>
          </div>
        </Col>
      </Row>


    </Drawer>
  </>;
};
