import React, { useState } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import { ProFormText } from '@ant-design/pro-components';
import { Client } from '../../services/Client';

const ChangePasswordModalForm = ({
  siteId,
  visible,
  onClose,
}: { siteId: number, visible: boolean, onClose: () => void }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    const { oldPassword, newPassword, confirmPassword } = values;

    if (newPassword !== confirmPassword) {
      message.error('Пароли не совпадают!');
      return;
    }

    // Отправка запроса на смену пароля
    try {
      setLoading(true);
      await Client.site.resetAdminPassword({ password: newPassword, siteId: siteId });
      message.success('Пароль успешно изменен!');
      form.resetFields();
      onClose(); // Закрытие модального окна
    } catch (error) {
      message.error('Ошибка при смене пароля!');
    }finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Сменить пароль"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={400}
      loading={loading}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }}
      >

        <Form.Item
          label="Новый пароль"
          name="newPassword"
          rules={[{ required: true, message: 'Пожалуйста, введите новый пароль!' }]}
        >
          <Input.Password/>
        </Form.Item>

        <Form.Item
          label="Подтверждение пароля"
          name="confirmPassword"
          rules={[{ required: true, message: 'Пожалуйста, подтвердите новый пароль!' }]}
        >
          <Input.Password/>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Сменить пароль
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModalForm;
